<template>
  <div>
    <!--面包屑-->
    <div class="page-breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/lessonschedule/pt' }"
          >私教列表</el-breadcrumb-item
        >
        <el-breadcrumb-item>私教查看</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <!--内容-->
    <div class="page-content-x">
      <!--标题-->
      <el-row>
        <el-col :span="12"
          ><h3>{{ page_name }}</h3></el-col
        >
      </el-row>
      <div style="height: 20px"></div>
      <!--详情-->
      <div>
        <div style="height: 20px"></div>
        <!--详情-->
        <el-form
          class="time-arrange-form"
          v-loading="loading"
          size="medium"
          :label-width="this.env.label_width"
        >
          <el-row>
            <el-col :span="16">
              <el-form-item label="企业名称">
                <span class="span-input">{{ form.company_name }}</span>
              </el-form-item>
              <el-form-item label="上课教练">
                <span class="span-input">{{ form.user_nick }}</span>
              </el-form-item>
              <el-form-item label="课程名">
                <span class="span-input">{{ form.lesson_name }}</span>
              </el-form-item>
              <el-form-item label="上课课程标题">
                <span class="span-input">
                  {{ form.title }}
                </span>
              </el-form-item>
              <el-form-item label="上课门店" v-if="!this.Tool.is_empty(form.coach_uuid)">
                <el-select
                  v-if="this.Tool.is_empty(good_uuid)"
                  :loading="select_loading"
                  v-model="form.store_s"
                  placeholder="请选择门店"
                  multiple
                  filterable
                  remote
                  :remote-method="get_store_list"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in store_list"
                    :key="item.store_uuid"
                    :label="item.store_name"
                    :value="item.store_uuid"
                  />
                </el-select>
                <div v-else>
                  <el-tag
                    style="margin-right: 10px"
                    :key="index"
                    v-for="(item, index) in form.store_s"
                    type="info"
                    effect="light"
                  >
                    {{ item.store_name }}
                  </el-tag>
                </div>
              </el-form-item>
              <el-form-item label="课程时长">
                <span class="span-input">{{ form.lesson_duration }}分钟</span>
              </el-form-item>
              <!--                            <el-form-item label="参考价格">-->
              <!--                                <span class="span-input">￥{{ form.price_indication }}</span>-->
              <!--                            </el-form-item>-->
              <el-form-item label="人数">
                <el-tag
                  style="margin-right: 12px"
                  v-for="(item, index) in form.sku_qty"
                  :key="index"
                >
                  1v{{ item }}
                </el-tag>
              </el-form-item>
              <el-form-item label="课时">
                <el-tag
                  style="margin-right: 12px"
                  v-for="(item, index) in form.sku_lesson_count"
                  :key="index"
                >
                  {{ item }}节
                </el-tag>
              </el-form-item>
              <el-form-item v-if="!this.Tool.is_empty(form.sku)" label="售价">
                <div v-for="(item, index) in form.sku" :key="index">
                  <div class="span-input" style="margin-bottom: 8px">
                    <el-tag style="min-width: 50px" size="medium"
                      >1v{{ item.qty }}</el-tag
                    >
                    <el-tag style="min-width: 50px" size="medium" type="success"
                      >{{ item.lesson_count }}节
                    </el-tag>
                    <span>{{
                      `￥${item.price} - 有效期：${
                        item.validity_time === 0 ? "不限制" : item.validity_time + "天"
                      }`
                    }}</span>
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="提示">
                <span class="span-input">{{ form.tip ? form.tip : "暂无提示内容" }}</span>
              </el-form-item>
              <el-form-item label="预约人数">
                <span class="span-input">累计{{ form.bookings_sum }}人</span>
              </el-form-item>

              <el-form-item>
                <el-button size="medium" @click="isreturn">返回</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      page_name: "私教",
      loading: false, // 加载状态
      select_loading: false, // 加载状态

      coach_list: [], // 教练列表
      lesson_list: [], // 课程列表
      store_list: [], // 门店列表
      // sku设置原始数据
      skuset: {
        qty: [],
        lesson_count: [],
      },
      good_uuid: "", // 私教课表uuid
      bookings_sum: 0, // 预约人数
      bookings: false, // 是否有预约
      form: {
        coach_uuid: "", // 选择的教练信息
        lesson_uuid: "", // 选择的课程信息
        store_s: [], // 选择的门店信息

        title: "", // 标题
        lesson_duration: "", // 时长
        price_indication: "", // 参考价格（节）

        sku_qty: [], // 设置规格人数
        sku_lesson_count: [], // 设置规格课程数量
        sku: [], // 规格

        tip: "", // 提示
      },
    };
  },
  // 创建
  created() {
    this.init();
  },
  // 安装
  mounted() {},
  methods: {
    // 权限判断
    is_auth(auth) {
      return this.Tool.is_auth(auth);
    },
    // 初始化
    init() {
      this.getskuset(); // 获取可用规格

      let good_uuid = this.$route.query.good_uuid;
      // console.log('good_uuid', good_uuid)
      if (!this.Tool.is_empty(good_uuid)) {
        this.good_uuid = good_uuid;
        this.getInfo(good_uuid);
      } else {
        this.get_coach_list(); // 获取可用教练
        this.get_lesson_list(); // 获取可用课程
        this.loading = false;
      }
    },
    // 获取可用规格
    getskuset() {
      let postdata = {
        api_name: "lessonschedule.pt.getskuset",
        token: this.Tool.get_l_cache("token"),
      };
      this.select_loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.select_loading = false;
        if (json.code === 0) {
          console.log("json.data", json.data);
          this.skuset = json.data;
        }
      });
    },
    // 选择规格
    selectsku() {
      let sku = [];
      this.form.sku_qty.sort(function (a, b) {
        return a - b;
      });
      this.form.sku_lesson_count.sort(function (a, b) {
        return a - b;
      });
      for (let v1 of this.form.sku_qty) {
        for (let v2 of this.form.sku_lesson_count) {
          // console.log('人数', v1, '课程数', v2)
          sku.push({
            qty: v1,
            lesson_count: v2,
            price: 0,
            validity_time: 0,
          });
        }
      }
      this.form.sku = sku;
    },
    // 获取教练列表
    get_coach_list(key) {
      // if (key === '') return false;
      let postdata = {
        api_name: "lessonschedule.pt.getusablecoach",
        token: this.Tool.get_l_cache("token"),
        key,
      };
      this.select_loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.select_loading = false;
        if (json.code === 0) {
          // console.log('json.data', json.data)
          this.coach_list = json.data;
        } else {
          // this.coach_list = []
        }
      });
    },
    // 选择教练
    selectcoach() {
      this.form.store_s = [];
      this.get_store_list(); // 获取可用门店
    },
    // 获取课程列表
    get_lesson_list(key) {
      // if (key === '') return false;
      let postdata = {
        api_name: "lessonschedule.pt.getusablelesson",
        token: this.Tool.get_l_cache("token"),
        key,
      };
      this.select_loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.select_loading = false;
        if (json.code === 0) {
          this.lesson_list = json.data;
        } else {
          // this.lesson_list = []
        }
      });
    },
    // 选择课程
    selectLesson(lesson_uuid = 0) {
      console.log("lesson_uuid", lesson_uuid);
      let lesson_data;
      for (let item of this.lesson_list) {
        if (item.lesson_uuid === lesson_uuid) {
          lesson_data = item;
          break;
        }
      }
      console.log("lesson_data", lesson_data);
      this.form.title = lesson_data.lesson_name;
      this.form.lesson_duration = lesson_data.lesson_duration;
      this.form.price_indication = lesson_data.price_indication;

      // 添加模式下
      // if (this.Tool.is_empty(this.good_uuid)) {
      // }
    },
    // 获取门店列表
    get_store_list(key) {
      // if (key === '') return false;
      let postdata = {
        api_name: "lessonschedule.pt.getusablestore",
        token: this.Tool.get_l_cache("token"),
        coach_uuid: this.form.coach_uuid,
        key,
      };
      this.select_loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.select_loading = false;
        if (json.code === 0) {
          this.store_list = json.data;
        } else {
          // this.store_list = []
          this.Tool.errormes(json);
        }
      });
    },
    // 获取排课信息
    getInfo(good_uuid = "") {
      let postdata = {
        api_name: "lessonschedule.pt.getinfo",
        token: this.Tool.get_l_cache("token"),
        good_uuid,
      };
      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.bookings_sum = json.data.bookings_sum;
          this.bookings = json.data.bookings_sum > 0 ? true : false;
          this.form = json.data;

          // this.selectcoach(json.data.coach_uuid)   // 选择教练
        } else {
          this.Tool.errormes(json);
        }
      });
    },
    // 保存
    save() {
      let postdata = {
        api_name: "lessonschedule.pt.issave",
        token: this.Tool.get_l_cache("token"),
      };
      // 判断是否有id
      if (!this.Tool.is_empty(this.good_uuid)) {
        postdata.good_uuid = this.good_uuid;
      }
      Object.assign(postdata, this.form);
      console.log("postdata", postdata);

      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          // 编辑模式下
          if (!this.Tool.is_empty(this.good_uuid)) {
            this.getInfo(this.good_uuid); // 刷新数据
          }
          this.$message({
            message: "操作成功",
            type: "success",
            duration: this.env.message_duration,
            onClose: () => {
              // 添加模式下
              if (this.Tool.is_empty(this.good_uuid)) {
                this.isreturn(); // 返回列表
              }
            },
          });
        } else {
          this.Tool.errormes(json);
        }
      });
    },
    // 返回
    isreturn() {
      // this.$router.push({path: '/lessonschedule/pt'})
      this.$router.go(-1);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*时间表*/
.time-arrange {
  /*border: #3a8ee6 1px solid;*/
  display: flex;
  justify-content: space-between;
  padding: 10px;
  box-shadow: 1px 1px 5px #d9d9d9;
  border-radius: 5px;
}

.font-size-mini {
  font-size: 12px;
}

/*课程表天*/
.timetable .time-arrange-column {
  width: 100% !important;
  position: relative !important;
}

.timetable .time-tip {
  width: 100%;
  position: absolute;
}

.timetable .time-h {
  height: 60px;
}

.timetable .time-h-border {
  border-top: #ebeef5 1px solid;
}

.timetable .time-arrange-column-store-classroom {
  width: calc(100% - 45px);
  margin-left: 40px;
  position: absolute;
}

.time-arrange-list {
  /*margin-right: 20px;*/
  /*border: 1px solid #888888;*/
  /*border-radius: 5px;*/
  /*box-shadow: 1px 1px 5px #cccccc;*/
  padding: 0 5px;
}

/*小号字体*/
.text-se {
  font-size: 12px;
}

.time-arrange-column {
  display: flex;
  /*min-height: 960px;*/
  height: 1440px;
  width: 30px;
  border: 1px solid #ebeef5;
  margin-right: 5px;
  font-size: 12px;
  /*margin-left: 5px;*/
}

.time-arrange-column-type {
  position: relative;
  width: 50%;
}

/*门店时间占用*/
.time-arrange-column-store-classroom-card {
  border-radius: 5px;
  width: 100%;
  background: #ffa940;
  /*position: relative;*/
  position: absolute;
  /*溢出滚动条*/
  overflow-x: auto;
  /*溢出隐藏*/
  /*overflow: hidden;*/
}

/*隐藏滚动条*/
.time-arrange-column-store-classroom-card::-webkit-scrollbar {
  width: 0 !important;
}

/*教练时间占用*/

.time-arrange-form .span-input {
  display: block;
  width: 100%;
  background-color: #f5f7fa;
  height: 36px;
  border-radius: 4px;
  font-size: 12px;
  padding: 0 15px;
  box-sizing: border-box;
  /*text-align: center;*/
}

.lesson_time {
  display: flex;
  margin-bottom: 10px;
}

.item_lesson_time {
  width: 80px !important;
}
</style>
